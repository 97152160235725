import { type Theme } from '@mui/material/styles'
import { type SvgIconProps } from '@mui/material'
import { colors as themeColors } from '../colors'

type Size = 'large' | 'medium' | 'small'
export const sizeMap: Record<Size, string> = {
	large: '24px',
	medium: '20px',
	small: '16px',
}

type Color = 'primary' | 'success'
const borderColorMap: Record<Color, string> = {
	primary: themeColors.grey300,
	success: themeColors.grey400,
}

const RadioButtonUncheckedIcon = (props: SvgIconProps) => {
	const size = sizeMap[props.fontSize ?? 'medium']

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="2 2 20 20"><path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8s8 3.58 8 8s-3.58 8-8 8"></path></svg>
	)
}

const RadioButtonCheckedIcon = (props: SvgIconProps) => {
	const size = sizeMap[props.fontSize ?? 'medium']

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="2 2 20 20"><path fill="currentColor" d="M12 17q2.075 0 3.538-1.463T17 12t-1.463-3.537T12 7T8.463 8.463T7 12t1.463 3.538T12 17m0 5q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"></path></svg>
	)
}

export const Radio = (theme: Theme) => {
	return {
		MuiRadio: {
			defaultProps: {
				icon: <RadioButtonUncheckedIcon />,
				checkedIcon: <RadioButtonCheckedIcon />,
				size: 'medium',
			},
			styleOverrides: {
				root: ({ ownerState }) => {
					const { color } = ownerState

					return {
						'&:not(.Mui-checked)': {
							color: borderColorMap[color],
						},
						'&.Mui-disabled': {
							color: theme.palette.grey[300],
							opacity: 0.4,
						},
						'.Mui-checked&.Mui-disabled': {
							color: theme.palette.primary.main,
						},
					}
				},
			},
		},
	}
}
