/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { type ButtonGroupProps } from '@mui/material'
import { type Theme, type SxProps } from '@mui/material/styles'
import { TEXT } from '../legacy-native-base'
import { colors as themeColors, GREEN, ERROR } from '../colors'

declare module '@mui/material/ButtonGroup' {
	interface ButtonGroupPropsVariantOverrides {
		soft: true
	}

	interface ButtonGroupPropsColorOverrides {
		greyScale: true
	}
}

// TODO: 추후 수정 필요
const disabledColorMap = {
	font: 'rgba(145, 158, 171, 0.8)',
	bg: {
		outlined: 'transparent',
		contained: 'rgba(145, 158, 171, 0.2)',
		text: 'transparent',
		soft: 'rgba(145, 158, 171, 0.2)',
	},
	border: {
		outlined: 'rgba(145, 158, 171, 0.2)',
		contained: 'rgba(145, 158, 171, 0.8)',
		text: 'rgba(145, 158, 171, 0.2)',
		soft: 'rgba(145, 158, 171, 0.8)',
	},
}
const enabledColorMap = {
	font: {
		greyScale: {
			outlined: 'rgba(33, 43, 54, 1)',
			contained: themeColors.white,
			text: 'rgba(33, 43, 54, 1)',
			soft: 'rgba(33, 43, 54, 1)',
		},
		primary: {
			outlined: GREEN.main,
			contained: themeColors.white,
			text: GREEN.main,
			soft: 'rgba(29, 174, 89, 1)',
		},
		error: {
			outlined: ERROR.main,
			contained: themeColors.white,
			text: ERROR.main,
			soft: ERROR.dark,
		},
	},
	bg: {
		greyScale: {
			outlined: 'inherit',
			contained: 'rgba(33, 43, 54, 1)',
			text: 'inherit',
			soft: 'rgba(145, 158, 171, 0.08)',
		},
		primary: {
			outlined: 'inherit',
			contained: GREEN.main,
			text: 'inherit',
			soft: 'rgba(57, 213, 121, 0.08)',
		},
		error: {
			outlined: 'inherit',
			contained: ERROR.main,
			text: 'inherit',
			soft: 'rgba(255, 86, 48, 0.08)',
		},
	},
	border: {
		greyScale: {
			outlined: 'rgba(145, 158, 171, 0.32)',
			contained: 'rgba(145, 158, 171, 0.32)',
			text: 'rgba(145, 158, 171, 0.32)',
			soft: 'rgba(145, 158, 171, 0.32)',
		},
		primary: {
			outlined: 'rgba(57, 213, 121, 0.48)',
			contained: 'rgba(29, 174, 89, 1)',
			text: 'rgba(57, 213, 121, 0.48)',
			soft: 'rgba(29, 174, 89, 0.24)',
		},
		error: {
			outlined: 'rgba(255, 86, 48, 0.48)',
			contained: ERROR.dark,
			text: 'rgba(255, 86, 48, 0.48)',
			soft: 'rgba(255, 86, 48, 0.24)',
		},
	},
}
const hoveredColorMap = {
	bg: {
		greyScale: {
			outlined: 'rgba(145, 158, 171, 0.08)',
			contained: 'rgba(69, 79, 91, 1)',
			text: 'rgba(145, 158, 171, 0.32)',
			soft: 'rgba(145, 158, 171, 0.24)',
		},
		primary: {
			outlined: 'rgba(57, 213, 121, 0.08)',
			contained: 'rgba(29, 174, 89, 1)',
			text: 'rgba(57, 213, 121, 0.48)',
			soft: 'rgba(57, 213, 121, 0.16)',
		},
		error: {
			outlined: 'rgba(255, 86, 48, 0.08)',
			contained: ERROR.dark,
			text: 'rgba(255, 86, 48, 0.48)',
			soft: 'rgba(255, 86, 48, 0.16)',
		},
	},
	border: {
		greyScale: {
			outlined: 'rgba(33, 43, 54, 1)',
			contained: 'rgba(69, 79, 91, 1)',
			text: 'rgba(145, 158, 171, 0.32)',
			soft: 'rgba(145, 158, 171, 0.32)',
		},
		primary: {
			outlined: GREEN.main,
			contained: 'rgba(29, 174, 89, 1)',
			text: 'rgba(57, 213, 121, 0.48)',
			soft: 'rgba(29, 174, 89, 0.24)',
		},
		error: {
			outlined: ERROR.main,
			contained: ERROR.dark,
			text: 'rgba(255, 86, 48, 0.48)',
			soft: 'rgba(255, 86, 48, 0.24)',
		},
	},
}
const shadowMap = {
	primary: '0px 8px 16px 0px rgba(0, 167, 111, 0.24)',
	greyScale: '0px 8px 16px 0px rgba(145, 158, 171, 0.16)',
	error: '0px 8px 16px 0px rgba(255, 86, 48, 0.24)',
}

export const ButtonGroup = (theme: Theme) => {
	type StyleOption = {
		props: ButtonGroupProps
		style: SxProps<Theme>
	}

	const sizes: StyleOption[] = [
		{
			props: { size: 'large', orientation: 'horizontal' },
			style: {
				height: 48,
				width: 240,
				...TEXT.sizes.btn_l_700, // TODO: 17, 24, 700; Figma에서는 15, 26, 700
			},
		},
		{
			props: { size: 'large', orientation: 'vertical' },
			style: {
				height: 146,
				width: 93,
				...TEXT.sizes.btn_l_700, // TODO: 17, 24, 700; Figma에서는 15, 26, 700
			},
		},
		{
			props: { size: 'medium', orientation: 'horizontal' },
			style: {
				height: 36,
				width: 212,
				...TEXT.sizes.btn_m_700, // TODO: 15, 21, 700; Figma에서는 14, 24, 700
			},
		},
		{
			props: { size: 'medium', orientation: 'vertical' },
			style: {
				height: 110,
				width: 82,
				...TEXT.sizes.btn_m_700, // TODO: 15, 21, 700; Figma에서는 14, 24, 700
			},
		},
		{
			props: { size: 'small', orientation: 'horizontal' },
			style: {
				height: 30,
				width: 181,
				...TEXT.sizes.btn_s_700, // TODO: 13, 21, 700; Figma에서는 15, 26, 700
			},
		},
		{
			props: { size: 'small', orientation: 'vertical' },
			style: {
				height: 92,
				width: 70,
				...TEXT.sizes.btn_s_700, // TODO: 13, 21, 700; Figma에서는 15, 26, 700
			},
		},
	]

	return {
		MuiButtonGroup: {
			defaultProps: {
				variant: 'outlined',
				color: 'primary',
				size: 'large',
				style: {
					borderRadius: 8,
				},
			},
			variants: sizes,
			styleOverrides: {
				root: ({ ownerState }) => {
					const { variant, color, orientation, style } = ownerState
					const { borderRadius } = style
					const isHoriz = orientation === 'horizontal'

					return {
						'&.MuiButtonGroup-root': { // top-level parent
							boxShadow: 'none',
						},
						'& .MuiButtonGroup-firstButton': {
							...(isHoriz
								? {
									borderBottomLeftRadius: borderRadius,
									borderTopLeftRadius: borderRadius,
								}
								: {
									borderTopLeftRadius: borderRadius,
									borderTopRightRadius: borderRadius,
								}),
						},
						'& .MuiButtonGroup-lastButton': {
							...(isHoriz
								? {
									borderBottomRightRadius: borderRadius,
									borderTopRightRadius: borderRadius,
								}
								: {
									borderBottomLeftRadius: borderRadius,
									borderBottomRightRadius: borderRadius,
								}),
						},
						'& .MuiButton-contained': {
							boxShadow: shadowMap[color],
						},
						'& .MuiButton-root': {
							color: enabledColorMap.font[color][variant],
							backgroundColor: enabledColorMap.bg[color][variant],
							borderColor: enabledColorMap.border[color][variant],

							'&.MuiButton-soft:not(.MuiButtonGroup-lastButton)': {
								borderRight: '1px solid',
							},
							'&:hover': {
								backgroundColor: hoveredColorMap.bg[color][variant],
								borderColor: hoveredColorMap.border[color][variant],

								'&.MuiButton-outlined': {
									border: '1.5px solid',
								},
							},
						},
						'& .Mui-disabled': {
							color: disabledColorMap.font,
							backgroundColor: disabledColorMap.bg[variant],
							borderColor: disabledColorMap.border[variant],
						},
					}
				},
			},
		},
	}
}
