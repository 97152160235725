import { useEffect } from 'react'
import { PATH } from '@bridge/core/constants/route-path'
import ErrorContainer from 'app/features/error/ErrorContainer'
import { Toast } from 'native-base'
import { type FallbackProps } from 'react-error-boundary'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'
import { getErrorMessage, INVALID_ACCESS, INVALID_JSON_PARSE, INVALID_TOKEN } from '@bridge/core/util/error'
import useAuth from 'app/hooks/useAuth'

export default function ErrorFallBack ({
	resetErrorBoundary,
	error,
}: FallbackProps) {
	const router = useRouter()
	const { logout } = useAuth()

	const goToLogin = async () => {
		// Auth 관련 에러일경우는 로그인 가능하게 로그인페이지로 이동하여
		// 로그인 페이지에서 렌딩 처리
		await router.push(PATH.LOGIN)
		resetErrorBoundary()
	}

	useEffect(() => {
		if (error) {
			if (
				// localStorage값이 이상한 케이스
				error.code === INVALID_JSON_PARSE ||
				// refresh token이 이상한 케이스
				error.code === INVALID_TOKEN
			) {
				// 임의로 수정하지 않는이상 발생 X
				void logout()
			}

			Sentry.captureException(error)
			Toast.show({ description: getErrorMessage(error) })
		}
	}, [error, logout])

	const defaultMessage = '알 수 없는 오류가 발생했어요.\n 문제가 지속되면 고객센터로 문의 해주세요.'

	const message = error?.code === INVALID_ACCESS
		? '잘못된 접근 입니다.'
		: defaultMessage

	return (
		<ErrorContainer
			title='오류 발생'
			message={message}
			onConfirm={goToLogin}
		/>
	)
}
