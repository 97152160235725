/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { type FabProps } from '@mui/material'
import { type SxProps, type Theme } from '@mui/material/styles'
import { colors as themeColors, GREEN } from '../colors'
import { TEXT } from '../legacy-native-base'

// 다른 Mui 컴포넌트들은 variant에 'outlined', 'contained', ...가 있음,
// 일관성을 유지하기 위해 Fab도 variant에 'outlined', 'contained', 'soft'를 추가;
// 기존에 Fab variant였던 'circular', 'extended'는 shape이라는 새로운 prop에 추가되었음;
// NOTE: 'circular', 'extended'를 variant에서 지운건 아니라서 넣으면 여전히 동작하지만 쓰지 말자...
declare module '@mui/material/Fab' {
	interface FabPropsVariantOverrides {
		contained: true
		outlined: true
		soft: true
	}

	interface FabOwnProps {
		shape?: 'circular' | 'extended'
	}

	interface FabPropsColorOverrides {
		greyScale: true
	}
}

const containedShadowMap = {
	greyScale: '0px 8px 16px 0px rgba(145, 158, 171, 0.16)',
	primary: '0px 8px 16px 0px rgba(34, 197, 94, 0.24)',
}

export const Fab = (theme: Theme) => {
	type StyleOption = {
		props: FabProps
		style: SxProps<Theme>
	}

	const sizes: StyleOption[] = [
		{
			props: { size: 'large', shape: 'circular' },
			style: {
				height: '56px',
				width: '56px',
				border: '1.17px solid',
				'& .MuiSvgIcon-root': {
					height: '28px',
					width: '28px',
				},
			},
		},
		{
			props: { size: 'large', shape: 'extended' },
			style: {
				height: 48,
				padding: '0 16px',
				border: '1.17px solid',
				...TEXT.sizes.btn_l_700, // TODO: 추후 수정 필요
			},
		},
		{
			props: { size: 'medium', shape: 'circular' },
			style: {
				height: '48px',
				width: '48px',
				border: '1px solid',
				'& .MuiSvgIcon-root': {
					height: '24px',
					width: '24px',
				},
			},
		},
		{
			props: { size: 'medium', shape: 'extended' },
			style: {
				height: 40,
				padding: '0 16px',
				border: '1px solid',
				...TEXT.sizes.btn_m_700, // TODO: 추후 수정 필요
			},
		},
		{
			props: { size: 'small', shape: 'circular' },
			style: {
				height: '40px',
				width: '40px',
				border: '0.83px solid',
				'& .MuiSvgIcon-root': {
					height: '20px',
					width: '20px',
				},
			},
		},
		{
			props: { size: 'small', shape: 'extended' },
			style: {
				height: 34,
				padding: '0 8px',
				border: '0.83px solid',
				...TEXT.sizes.btn_s_700, // TODO: 추후 수정 필요
			},
		},
		{
			props: { shape: 'extended' },
			style: {
				width: 'auto',
				borderRadius: 500,
			},
		},
	]

	const colors: StyleOption[] = [
		{
			props: { variant: 'outlined', disabled: true },
			style: {
				color: 'rgba(145, 158, 171, 0.8)',
				backgroundColor: 'transparent',
				border: '1px solid',
				borderColor: 'rgba(145, 158, 171, 0.2)',
			},
		},
		{
			props: { variant: 'outlined', color: 'greyScale' },
			style: {
				color: 'rgba(33, 43, 54, 1)',
				backgroundColor: 'transparent',
				borderColor: themeColors.grey200,
				boxShadow: 'none',
				'&:hover': {
					border: '1.5px solid',
					borderColor: 'rgba(33, 43, 54, 1)',
					backgroundColor: themeColors.grey100,
				},
			},
		},
		{
			props: { variant: 'outlined', color: 'primary' },
			style: {
				color: GREEN.main,
				backgroundColor: 'transparent',
				borderColor: 'rgba(57, 213, 121, 0.48)',
				boxShadow: 'none',
				'&:hover': {
					border: '1.5px solid',
					borderColor: GREEN.main,
					backgroundColor: 'rgba(57, 213, 121, 0.08)',
				},
			},
		},
		{
			props: { variant: 'contained', disabled: true },
			style: {
				color: 'rgba(145, 158, 171, 0.8)',
				backgroundColor: 'rgba(145, 158, 171, 0.2)',
				border: 0,
			},
		},
		{
			props: { variant: 'contained', color: 'greyScale' },
			style: {
				color: themeColors.white,
				backgroundColor: 'rgba(33, 43, 54, 1)',
				border: 0,
				boxShadow: containedShadowMap.greyScale,
				'&:hover': {
					backgroundColor: 'rgba(69, 79, 91, 1)',
				},
			},
		},
		{
			props: { variant: 'contained', color: 'primary' },
			style: {
				color: themeColors.white,
				backgroundColor: GREEN.main,
				border: 0,
				boxShadow: containedShadowMap.primary,
				'&:hover': {
					backgroundColor: GREEN.dark,
				},
			},
		},
		{
			props: { variant: 'soft', disabled: true },
			style: {
				color: 'rgba(145, 158, 171, 0.8)',
				backgroundColor: 'rgba(145, 158, 171, 0.2)',
				border: 0,
			},
		},
		{
			props: { variant: 'soft', color: 'greyScale' },
			style: {
				color: 'rgba(33, 43, 54, 1)',
				backgroundColor: 'rgba(145, 158, 171, 0.08)',
				border: 0,
				boxShadow: 'none',
				'&:hover': {
					backgroundColor: 'rgba(145, 158, 171, 0.24)',
				},
			},
		},
		{
			props: { variant: 'soft', color: 'primary' },
			style: {
				color: 'rgba(29, 174, 89, 1)',
				backgroundColor: 'rgba(57, 213, 121, 0.08)',
				border: 0,
				boxShadow: 'none',
				'&:hover': {
					backgroundColor: 'rgba(57, 213, 121, 0.16)',
				},
			},
		},
	]

	return {
		MuiFab: {
			defaultProps: {
				size: 'large',
				color: 'greyScale',
				shape: 'circular',
				variant: 'outlined',
			},
			variants: [...sizes, ...colors],
			styleOverrides: {
				root: ({ ownerState }) => {
					const { variant } = ownerState

					return {
						'&.Mui-disabled': {
							color: 'rgba(145, 158, 171, 0.8)',
							backgroundColor: variant === 'outlined' ? 'transparent' : 'rgba(145, 158, 171, 0.2)',
							border: variant === 'outlined' ? '1px solid rgba(145, 158, 171, 0.2)' : 0,
						},
					}
				},
			},
		},
	}
}
