import { type Theme } from '@mui/material/styles'
import { colors as themeColors } from '../colors'

export const Select = (theme: Theme) => {
	return {
		MuiSelect: {
			styleOverrides: {
				root () {
					return {
						'&.Mui-disabled': {
							backgroundColor: themeColors.grey50,
						},
						'&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
							borderColor: themeColors.grey300,
						},
					}
				},
			},
		},
	}
}
