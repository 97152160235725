import { Stack, Box, Typography } from '@bridge/ui'
import colors from 'app/colors'

type Theme = 'white' | 'black' | 'primary' | 'grey'

type HeaderProps = {
	theme?: Theme
	title?: string
	leftButton?: React.ReactNode
	rightButton?: React.ReactNode
}

const ThemeColor = {
	white: {
		bg: '#fff',
		font: '#191F28', // gray900
	},
	black: {
		bg: '#000',
		font: '#fff',
	},
	primary: {
		bg: '#2CD167', // green400
		font: '#fff',
	},
	grey: {
		bg: colors.grey50,
		font: colors.black,
	},
}

function Header ({
	title,
	leftButton,
	rightButton,
	theme = 'white',
}: HeaderProps) {
	return (
		<Stack zIndex={60}>
			<Box height={leftButton ?? rightButton ? '50px' : '0px'} />
			<Stack
				width='100%'
				maxWidth='500px'
				alignItems='center'
				sx={{
					alignSelf: 'center',
					backgroundColor: ThemeColor[theme].bg,
					position: 'fixed',
					zIndex: 99,
				}}
			>
				<Stack
					direction='row'
					width='100%'
					height='50px'
					maxWidth='500px'
					alignSelf='center'
					alignItems='center'
					justifyContent='center'
					position='relative'
				>
					<Box display='flex' position='absolute' left='10px'>
						{leftButton}
					</Box>
					<Typography color={ThemeColor[theme].font} fontSize={17} fontWeight={600}>
						{title}
					</Typography>
					<Box position='absolute' right='10px'>
						{rightButton}
					</Box>
				</Stack>
			</Stack>
		</Stack>
	)
}

export default Header
