import useAuth from 'app/hooks/useAuth'
import { type FallbackProps } from 'react-error-boundary'

export default function MainErrorFallBack ({
	resetErrorBoundary,
	error,
}: FallbackProps) {
	const { logout } = useAuth()

	if (error) {
		void logout().then(resetErrorBoundary)
	}

	return null
}
