/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { type IconButtonProps } from '@mui/material'
import { type SxProps, type Theme } from '@mui/material/styles'
import { colors as themeColors } from '../colors'

declare module '@mui/material/IconButton' {
	interface IconButtonPropsColorOverrides {
		greyScale: true
	}
}

export const hoveredColorMap = {
	default: themeColors.grey600,
	primary: themeColors.green500,
}

export const bgColorMap = {
	default: themeColors.grey50,
	primary: themeColors.green50,
	disabled: themeColors.transparent,
}

export const IconButton = (theme: Theme) => {
	type StyleOption = {
		props: IconButtonProps
		style: SxProps<Theme>
	}

	const sizes: StyleOption[] = [
		{
			props: { size: 'large' },
			style: {
				height: '40px',
				width: '40px',
				'& .MuiSvgIcon-root': {
					height: '28px',
					width: '28px',
				},
			},
		},
		{
			props: { size: 'medium' },
			style: {
				height: '32px',
				width: '32px',
				'& .MuiSvgIcon-root': {
					height: '24px',
					width: '24px',
				},
			},
		},
		{
			props: { size: 'small' },
			style: {
				height: '24px',
				width: '24px',
				'& .MuiSvgIcon-root': {
					height: '20px',
					width: '20px',
				},
			},
		},
	]

	const colors: StyleOption[] = [
		{
			props: { disabled: true },
			style: {
				color: themeColors.grey400 + '!important',
				backgroundColor: bgColorMap.disabled,
			},
		},
		{
			props: { color: 'greyScale' },
			style: {
				color: themeColors.grey500,
				'&:hover': {
					color: hoveredColorMap.default,
					backgroundColor: bgColorMap.default,
				},
			},
		},
		{
			props: { color: 'primary' },
			style: {
				color: themeColors.green400,
				'&:hover': {
					color: hoveredColorMap.primary,
					backgroundColor: bgColorMap.primary,
				},
			},
		},
	]

	return {
		MuiIconButton: {
			defaultProps: {
				size: 'large',
				color: 'greyScale',
				style: {
					borderRadius: '8px',
				},
			},
			variants: [...sizes, ...colors],
		},
	}
}
