import { isAxiosError } from 'axios'
import { isClientError } from './client'
import { type KnowreError } from './server'

export function getErrorMessage (error: unknown) {
	if (isAxiosError(error)) {
		if (error.response) {
			// 요청 성공
			// The request was made and the server responded with a status code
			return error.response?.data?.error?.message ?? error.response?.data?.message ?? '서버에서 문제가 발생했어요'
		}

		if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			return '인터넷 연결이 불안정해요.'
		}

		// Something happened in setting up the request that triggered an Error
		return '인터넷 연결이 불안정해요.'
	}

	// 클라이언트 커스텀 에러
	if (isClientError(error)) {
		return error.message
	}

	// runtime Error
	return '시스템상 문제가 발생했어요.'
}

export function getTraceCode (errorResponse: KnowreError) {
	const errorMessage = errorResponse?.response?.data?.error.message ?? ''

	const match = errorMessage.match(/KTC:(\w+)/)

	return match?.[1] ?? ''
}
