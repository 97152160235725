import { INVALID_JSON_PARSE, ClientError } from '@bridge/core/util/error'

// 휴대폰 변경 opt검증시에 사용
const ACCESS_TOKEN = 'accessToken'
const REFRESH_TOKEN_KEY = 'refreshToken'
const AUTH_ID_TOKEN_KEY = 'token'

const getItem = (key: string) => {
	try {
		const item = localStorage.getItem(key)

		return item
			? JSON.parse(item)
			: null
	} catch (e) {
		localStorage.removeItem(key)

		throw new ClientError({
			code: INVALID_JSON_PARSE,
			message: `Invalid storage value, key: ${key}`,
		})
	}
}

const setItem = (key: string, value: string) => {
	localStorage.setItem(key, JSON.stringify(value))
}

const removeItem = (key: string) => {
	localStorage.removeItem(key)
}

const clearAll = () => {
	localStorage.clear()
}

const setIdToken = (idToken: string) => {
	setItem(AUTH_ID_TOKEN_KEY, idToken)
}

const setRefreshToken = (refreshToken: string) => {
	setItem(REFRESH_TOKEN_KEY, refreshToken)
}

const setAccessToken = (accessToken: string) => {
	setItem(ACCESS_TOKEN, accessToken)
}

const getIdToken = (): string => getItem(AUTH_ID_TOKEN_KEY)

const getRefreshToken = (): string => getItem(REFRESH_TOKEN_KEY)

const getAccessToken = (): string => getItem(ACCESS_TOKEN)

const clearAuth = () => {
	removeItem(AUTH_ID_TOKEN_KEY)
	removeItem(REFRESH_TOKEN_KEY)
	removeItem(ACCESS_TOKEN)
}

export {
	getItem,
	setItem,
	removeItem,
	clearAll,
	setAccessToken,
	getAccessToken,
	setIdToken,
	setRefreshToken,
	getIdToken,
	getRefreshToken,

	clearAuth,
}
