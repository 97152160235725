import { createContext, useCallback, useState, type PropsWithChildren } from 'react'
import noop from 'lodash/noop'

type ModalNode = React.FC<{ open: boolean, param: any }>

type ModalInfo = {
	ModalNode: ModalNode
	isOpen: boolean
	param: any
}

type ModalContextType = {
	setModal: (id: string, renderItem: ModalNode) => void
	deleteModal: (id: string) => void
	showModal: (id: string, param: unknown) => void
	hideModal: (id: string) => void
}

export const ModalContext = createContext<ModalContextType>({
	setModal: noop,
	deleteModal: noop,
	showModal: noop,
	hideModal: noop,
})

export function ModalProvider ({ children }: PropsWithChildren) {
	const [modalMap, setModalMap] = useState<Map<string, ModalInfo>>(new Map())

	const showModal = useCallback((id: string, param: any) => {
		setModalMap((prev) => {
			const newModalMap = new Map(prev)

			const renderItemInfo = newModalMap.get(id)

			if (!renderItemInfo) {
				throw new Error('setModal should be executed first.')
			}

			newModalMap.set(id, {
				...renderItemInfo,
				isOpen: true,
				param,
			})

			return newModalMap
		})
	}, [])

	const hideModal = useCallback((id: string) => {
		setModalMap((prev) => {
			const newModalMap = new Map(prev)

			const renderItemInfo = newModalMap.get(id)

			if (!renderItemInfo) {
				throw new Error('setModal should be executed first.')
			}

			newModalMap.set(id, {
				...renderItemInfo,
				isOpen: false,
				param: null,
			})

			return newModalMap
		})
	}, [])

	const setModal = useCallback((id: string, node: ModalNode) => {
		setModalMap((prev) => {
			const newModal = new Map(prev)

			newModal.set(id, {
				param: null,
				isOpen: false,
				ModalNode: node,
			})

			return newModal
		})
	}, [])

	const deleteModal = useCallback((id: string) => {
		setModalMap((prev) => {
			const newModalMap = new Map(prev)
			newModalMap.delete(id)

			return newModalMap
		})
	}, [])

	return (
		<ModalContext.Provider value={{ showModal, hideModal, setModal, deleteModal }}>
			{children}

			{[...modalMap.entries()].map(([id, { ModalNode, param, isOpen }]) => (
				<ModalNode
					key={id}
					open={isOpen}
					param={param}
				/>
			))}
		</ModalContext.Provider>
	)
}
