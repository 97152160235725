import { v4 } from 'uuid'
import { noop, reject } from 'lodash-es'
import { Box, Grow, Snackbar as MUISnackbar, Stack, Typography } from '@mui/material'
import { useEffect, createContext, useContext, useState, type PropsWithChildren } from 'react'
import { colors } from '../theme/colors'

const ANIMATION_TIME = 500 // 애니메이션 소요 시간
const DELETE_ANIMATION_TIME = 3000 // Snackbar 삭제 애니메이션이 시작되는 시간 (snackbar 생성 시점으로부터 3초)
const DELETE_DOM_TIME = DELETE_ANIMATION_TIME + ANIMATION_TIME // 실제 Snackbar 가 dom 에서 사라지는 시간 (snackbar 생성 시점으로부터 3.5초)

type ToastContextType = {
	show: (option: { description: string }) => void
}

const ToastContext = createContext<ToastContextType>({
	show: noop,
})

export function ToastProvider ({ children }: PropsWithChildren) {
	const [snackbarList, setSnackbarList] = useState<Array<{ description: string, key: string }>>([])

	function show ({ description }: { description: string }) {
		const key = new Date().getTime().toString() + v4()
		setSnackbarList(prev => [...prev, { description, key }])
		setTimeout(hide(key), DELETE_DOM_TIME)
	}

	function hide (key: string) {
		return () => {
			setSnackbarList(prev => reject(prev, (item) => item.key === key))
		}
	}

	return (
		<ToastContext.Provider value={{ show }}>
			{children}
			<InitializeToast />
			<MUISnackbar open={snackbarList.length > 0} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
				<Stack flexDirection='column' gap={1} alignItems='center'>
					{
						snackbarList.map(({ description, key }) => <AnimatedToast description={description} key={key} />)
					}
				</Stack>
			</MUISnackbar>
		</ToastContext.Provider>
	)
}

function AnimatedToast ({ description }: { description: string }) {
	const [animationIn, setAnimationIn] = useState(true)

	useEffect(() => {
		setTimeout(() => { setAnimationIn(false) }, DELETE_ANIMATION_TIME)
	}, [])

	return (
		<Grow in={animationIn} timeout={ANIMATION_TIME}>
			<Box style={{ backgroundColor: colors.grey800, maxWidth: 400, padding: '12px 20px', borderRadius: 12, whiteSpace: 'pre-line' }}>
				<Typography variant='p_s_700' color={colors.white}>{description}</Typography>
			</Box>
		</Grow>
	)
}

const toastHook = { show: noop }
function InitializeToast () {
	const { show } = useToast()
	toastHook.show = show
	return null
}

export const Toast = {
	show: (arg: { description: string }) => { toastHook.show(arg) },
}

export const useToast = () => useContext(ToastContext)
