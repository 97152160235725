const CLIENT_CUSTOM_ERROR = 'CLIENT_CUSTOM_ERROR'
export const INVALID_ACCESS = 'INVALID_ACCESS'

export const EMPTY_TOKEN = 'EMPTY_TOKEN'
export const INVALID_JSON_PARSE = 'INVALID_JSON_PARSE'

export const INVALID_TOKEN = 'INVALID_TOKEN'

// 클라이언트 커스텀 에러
export class ClientError extends Error {
	public code: string

	constructor ({
		code = CLIENT_CUSTOM_ERROR,
		message,
	}) {
		super(message)

		this.code = code
	}
}

export const isClientError = (error: any): error is ClientError => {
	return error instanceof ClientError
}
