/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { type Theme } from '@mui/material/styles'
import { colors as themeColors } from '../colors'

declare module '@mui/material/Switch' {
	interface SwitchPropsSizeOverrides {
		large: true
	}
}

type Size = 'large' | 'medium' | 'small'
const sizeMap: Record<Size, { thumb: number, base: { w: number, h: number } }> = {
	large: {
		thumb: 20,
		base: {
			w: 48,
			h: 24,
		},
	},
	medium: {
		thumb: 16,
		base: {
			w: 40,
			h: 20,
		},
	},
	small: {
		thumb: 12,
		base: {
			w: 32,
			h: 16,
		},
	},
}

type Color = 'primary' | 'success'
const colorMap: Record<Color, { off: string, on: string }> = {
	primary: {
		off: themeColors.grey300,
		on: themeColors.green400,
	},
	success: {
		off: themeColors.grey400,
		on: themeColors.green600,
	},
}

export const Switch = (theme: Theme) => {
	return {
		MuiSwitch: {
			defaultProps: {
				size: 'large',
				disableRipple: true,
			},
			styleOverrides: {
				root: ({ ownerState }) => {
					const { size, color } = ownerState
					const margin = 2
					const translateX = (sizeMap[size].base.w as number) - sizeMap[size].thumb - margin * 2

					return {
						width: sizeMap[size].base.w,
						height: sizeMap[size].base.h,
						padding: 0,

						'& .MuiSwitch-thumb': {
							margin,
							boxShadow: 'none',
							boxSizing: 'border-box',
							color: themeColors.white,
							width: sizeMap[size].thumb,
							height: sizeMap[size].thumb,
						},
						'& .MuiSwitch-track': {
							borderRadius: 16,
							backgroundColor: colorMap[color].off,
							opacity: 1,
						},
						'& .MuiSwitch-switchBase': {
							padding: 0,
							transitionDuration: '300ms',

							'&.Mui-checked': {
								transform: `translateX(${translateX}px)`,
								color: themeColors.white,

								'& + .MuiSwitch-track': {
									backgroundColor: colorMap[color].on,
									opacity: 1,
									border: 0,
								},
								'&.Mui-disabled + .MuiSwitch-track': {
									backgroundColor: themeColors.green400,
									opacity: 0.5,
								},
							},
							'&.Mui-disabled + .MuiSwitch-track': { // MuiSwitch-switchBase 형제 요소
								backgroundColor: themeColors.grey300,
								opacity: 0.5,
							},
						},
					}
				},
			},
		},
	}
}
