// 기존 native-base에 정의된 테마 옵션
export const TEXT = {
	sizes: {
		h1_900: {
			fontSize: '72px',
			lineHeight: '80px',
			fontWeight: 900,
		},
		h1_800: {
			fontSize: '72px',
			lineHeight: '80px',
			fontWeight: 800,
		},
		h2_900: {
			fontSize: '60px',
			lineHeight: '80px',
			fontWeight: 900,
		},
		h2_800_main: {
			fontSize: '50px',
			lineHeight: '70px',
			fontWeight: 800,
		},
		h2_900_main: {
			fontSize: '50px',
			lineHeight: '70px',
			fontWeight: 900,
		},
		// TODO: 폰트 크기 다른이유 확인 필요
		h2_400: {
			fontSize: '60px',
			lineHeight: '80px',
			fontWeight: 400,
		},
		h2_800: {
			fontSize: '48px',
			lineHeight: '64px',
			fontWeight: 800,
		},
		h3_700: {
			fontSize: '36px',
			lineHeight: '50px',
			fontWeight: 700,
		},
		h3_800: {
			fontSize: '36px',
			lineHeight: '50px',
			fontWeight: 800,
		},
		h3_900: {
			fontSize: '36px',
			lineHeight: '50px',
			fontWeight: 900,
		},
		h3_900_main: {
			fontSize: '36px',
			lineHeight: '60px',
			fontWeight: 900,
		},
		h4_800: {
			fontSize: '32px',
			lineHeight: '45px',
			fontWeight: 800,
		},
		h4_700: {
			fontSize: '32px',
			lineHeight: '54px',
			fontWeight: 700,
		},
		h4_700_main: {
			fontSize: '32px',
			lineHeight: '45px',
			fontWeight: 700,
		},
		h4_600: {
			fontSize: '32px',
			lineHeight: '54px',
			fontWeight: 600,
		},
		h4_500: {
			fontSize: '32px',
			lineHeight: '54px',
			fontWeight: 500,
		},
		h4_500_main: {
			fontSize: '32px',
			lineHeight: '45px',
			fontWeight: 500,
		},
		h5_500_main: {
			fontSize: '24px',
			lineHeight: '40px',
			fontWeight: 500,
		},
		h5_500: {
			fontSize: '24px',
			lineHeight: '36px',
			fontWeight: 500,
		},
		h5_600: {
			fontSize: '24px',
			lineHeight: '36px',
			fontWeight: 600,
		},
		h5_700: {
			fontSize: '24px',
			lineHeight: '36px',
			fontWeight: 700,
		},
		h5_800: {
			fontSize: '24px',
			lineHeight: '36px',
			fontWeight: 800,
		},
		h5_900: {
			fontSize: '24px',
			lineHeight: '36px',
			fontWeight: 900,
		},
		h6_400: {
			fontSize: '20px',
			lineHeight: '32px',
			fontWeight: 400,
		},
		h6_500: {
			fontSize: '20px',
			lineHeight: '28px',
			fontWeight: 500,
		},
		h6_600: {
			fontSize: '20px',
			lineHeight: '28px',
			fontWeight: 600,
		},
		h6_700: {
			fontSize: '20px',
			lineHeight: '28px',
			fontWeight: 700,
		},
		h6_800: {
			fontSize: '20px',
			lineHeight: '28px',
			fontWeight: 800,
		},
		h6_800_main: {
			fontSize: '20px',
			lineHeight: '30px',
			fontWeight: 800,
		},
		h7_500: {
			fontSize: '17px',
			lineHeight: '24px',
			fontWeight: 500,
		},
		h7_500_main: {
			fontSize: '17px',
			lineHeight: '30px',
			fontWeight: 500,
		},
		h7_600: {
			fontSize: '17px',
			lineHeight: '24px',
			fontWeight: 600,
		},
		h7_700: {
			fontSize: '17px',
			lineHeight: '24px',
			fontWeight: 700,
		},
		h7_800: {
			fontSize: '17px',
			lineHeight: '24px',
			fontWeight: 800,
		},
		p_s_500: {
			fontSize: '13px',
			lineHeight: '21px',
			fontWeight: 500,
		},
		p_s_600: {
			fontSize: '13px',
			lineHeight: '21px',
			fontWeight: 600,
		},
		p_s_700: {
			fontSize: '13px',
			lineHeight: '21px',
			fontWeight: 700,
		},
		p_s_800: {
			fontSize: '13px',
			lineHeight: '21px',
			fontWeight: 800,
		},
		p_m_400: {
			fontSize: '15px',
			lineHeight: '24px',
			fontWeight: 400,
		},
		p_m_500: {
			fontSize: '15px',
			lineHeight: '24px',
			fontWeight: 500,
		},
		p_m_600: {
			fontSize: '15px',
			lineHeight: '24px',
			fontWeight: 600,
		},
		p_m_700: {
			fontSize: '15px',
			lineHeight: '24px',
			fontWeight: 700,
		},
		p_m_800: {
			fontSize: '15px',
			lineHeight: '24px',
			fontWeight: 800,
		},
		p_xs_500: {
			fontSize: '11px',
			lineHeight: '18px',
			fontWeight: 500,
		},
		p_xs_600: {
			fontSize: '11px',
			lineHeight: '18px',
			fontWeight: 600,
		},
		p_xs_700: {
			fontSize: '11px',
			lineHeight: '18px',
			fontWeight: 700,
		},
		p_xs_800: {
			fontSize: '11px',
			lineHeight: '18px',
			fontWeight: 800,
		},
		btn_m_700: {
			fontSize: '15px',
			lineHeight: '21px',
			fontWeight: 700,
		},
		btn_s_600: {
			fontSize: '13px',
			lineHeight: '21px',
			fontWeight: 600,
		},
		btn_s_700: {
			fontSize: '13px',
			lineHeight: '21px',
			fontWeight: 700,
		},
		btn_l_700: {
			fontSize: '17px',
			lineHeight: '24px',
			fontWeight: 700,
		},
		Label_BOLD_700: {
			fontSize: '9px',
			lineHeight: '16px',
			fontWeight: 700,
		},
		libre: {
			fontSize: '36px',
			lineHeight: '50px',
			letter: '6px',
			fontWeight: 400,
		},
		libre_base: {
			fontSize: '20px',
			lineHeight: '32px',
			letter: '3',
			fontWeight: 400,
		},
		btn_xs_700: {
			fontSize: '11px',
			lineHeight: '18px',
			fontWeight: 700,
		},
	},
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const legacyNativeBaseTheme = {
	colors: {
		primary: {
			50: '#eafbf0',
			100: '#c0f2d2',
			200: '#96e9b3',
			300: '#6ce095',
			400: '#2cd167',
			500: '#28be5d',
			600: '#1f9349',
			700: '#166934',
			800: '#0d3f1f',
			900: '#04150a',
		},
		pink: {
			50: '#feebf2',
			100: '#fbc4d7',
			200: '#f89cbd',
			500: '#ef266e',
			700: '#b20d48',
		},
		black: {
			400: '#191f28',
			500: '#000000',
			600: '#000000',
			700: '#333d4b',
		},
		white: {
			50: '#ffffff',
			100: '#f2f4f6',
			200: '#f2f4f6',
			400: '#8b95a1',
			500: '#8b95a1',
			600: '#ffffff',
			700: '#ffffff',
		},
		gray: {
			50: '#F9FAFB',
			100: '#F2F4F6',
			200: '#e5e8eb',
			300: '#D1D6DB',
			400: '#B0B8C1',
			500: '#8b95a1',
			600: '#6b7684',
			700: '#4e5968',
			800: '#333d4b',
			900: '#191f28',
		},
		teal: {
			400: '#30B6B6',
		},
		red: {
			300: '#ff645f',
			500: '#f04452',
		},
		blue: {
			500: '#21a8f3',
			600: '#0C93DE',
		},
		error: {
			600: '#f04452',
		},
		singletons: {
			white: '#ffffff',
			black: '#000000',
		},
	},
	fontConfig: {
		SUIT: {
			100: {
				normal: 'SUIT-Thin',
			},
			200: {
				normal: 'SUIT-ExtraLight',
			},
			300: {
				normal: 'SUIT-Light',
			},
			400: {
				normal: 'SUIT-Regular',
			},
			500: {
				normal: 'SUIT-Medium',
			},
			600: {
				normal: 'SUIT-SemiBold',
			},
			700: {
				normal: 'SUIT-Bold',
			},
			800: {
				normal: 'SUIT-ExtraBold',
			},
			900: {
				normal: 'SUIT-Heavy',
			},
		},
		LIBRE_BODONI: {
			400: {
				normal: 'LIBRE_BODONI',
			},
		},
	},
	fonts: {
		heading: 'SUIT',
		body: 'SUIT',
		// safari, native에서는 fontFamily를 적용하려면 여기에 정의된 alias를 사용해야됨
		libre: 'LIBRE_BODONI',
	},
	fontSizes: {
		sm: '13px',
		md: '17px',
	},
	shadows: {
		1: {
			shadowColor: '#001b37',
			shadowOffset: {
				width: 0,
				height: 8,
			},
			shadowOpacity: 0.1,
			shadowRadius: 16,
		},
	},
	components: {
		Text: TEXT,
		Button: {
			baseStyle: {
				borderRadius: '16px',
			},
			sizes: {
				lg: {
					_text: {
						fontSize: '20px',
						fontWeight: 700,
					},
				},
				md: {
					_text: {
						fontSize: '17px',
						fontWeight: 700,
					},
				},
				sm: {
					_text: {
						fontSize: '15px',
						fontWeight: 700,
					},
				},
				xs: {
					_text: {
						fontSize: '13px',
						fontWeight: 700,
					},
				},
			},
			variants: {
				// @ts-ignore
				solid: ({ colorScheme }: { colorScheme: string }) => {
					return {
						bg: `${colorScheme}.400`,
						_hover: {
							bg: `${colorScheme}.600`,
						},
						_pressed: {
							bg: `${colorScheme}.600`,
						},
						_disabled: {
							opacity: 1,
							bg: '#e5e8eb',
							_text: {
								color: '#b0b8c1',
							},
						},
						_text: {
							color: '#ffffff',
						},
					}
				},
				// @ts-ignore
				outline: ({ colorScheme }: { colorScheme: string }) => {
					return {
						bg: `${colorScheme}.50`,
						borderColor: `${colorScheme}.100`,
						_hover: {
							bg: `${colorScheme}.100`,
							borderColor: `${colorScheme}.200`,
						},
						_pressed: {
							bg: `${colorScheme}.100`,
							borderColor: `${colorScheme}.200`,
						},
						_disabled: {
							opacity: 1,
							bg: '#e5e8eb',
							borderColor: '#d1d6db',
							_text: {
								color: '#b0b8c1',
							},
						},
						_text: {
							color: `${colorScheme}.400`,
						},
					}
				},
				ghost: () => {
					return {
						bg: 'singletons.white',
						borderWidth: 1,
						borderColor: 'gray.200',
						_text: {
							color: 'gray.500',
						},
					}
				},
				unstyled: () => {
					return {
						_text: {
							color: 'gray.700',
						},
					}
				},
			},
		},
		Input: {
			baseStyle: {
				px: '20px',
				color: 'gray.900',
				borderRadius: '12px',
				borderColor: 'gray.200',
				placeholderTextColor: 'gray.500',
				backgroundColor: '#ffffff',

				_disabled: {
					backgroundColor: 'gray.200',
					borderColor: 'gray.300',
					opacity: '1',
				},
				_hover: {
					borderWidth: 2,
					borderColor: 'gray.900',
				},
				_focus: {
					borderWidth: 2,
					borderColor: 'gray.900',
					backgroundColor: '#ffffff',

					_stack: {
						style: {
							outlineWidth: '0',
							boxShadow: '',
						},
					},

					_hover: {
						borderWidth: 2,
						borderColor: 'gray.900',
					},
					_ios: {
						selectionColor: '#ffffff',
					},
					_android: {
						selectionColor: '#ffffff',
					},
				},
			},
		},
		FormControlLabel: {
			baseStyle: {
				_text: {
					fontWeight: 600,
					color: 'gray.700',
				},
			},
		},
		Select: {
			baseStyle: {
				pr: '2px',
				_disabled: {
					placeholderTextColor: 'gray.500',
				},
			},
		},
		SliderThumb: {
			baseStyle: {
				_hover: {
					_web: {
						outlineWidth: '0px',
					},
				},
				_focus: {
					_web: {
						outlineWidth: '0px',
					},
				},
				_pressed: {
					_interactionBox: {
						borderWidth: '1',
						borderColor: 'primary.400',
					},
					borderWidth: 0,
				},
			},
		},
		Toast: {
			baseStyle: {
				bg: 'gray.800',
				rounded: '12px',
				pt: '12px',
				pb: '12px',
				pl: '20px',
				pr: '20px',
				bottom: '40px',
				_space: {
					space: 1,
				},
				_description: {
					color: 'singletons.white',
				},
			},
		},
		Progress: {
			baseStyle: {
				bg: 'gray.100',
			},
			variants: {
				// @ts-ignore
				gradient: ({ colorScheme }: { colorScheme: string }) => {
					return {
						_filledTrack: {
							bg: {
								linearGradient: {
									colors: [
										`${colorScheme}.200`,
										`${colorScheme}.400`,
									],
									start: [0, 0],
									end: [1, 0],
								},
							},
						},
					}
				},
			},
		},
		Checkbox: {
			sizes: {
				sm: {
					_text: {
						fontSize: '13px',
						fontWeight: 500,
					},
				},
			},
			baseStyle: {
				borderColor: 'gray.300',
				_text: {
					color: 'gray.700',
					ml: 0,
				},
				_checked: {
					bg: 'primary.400',
					borderColor: 'primary.400',
					_hover: {
						bg: 'primary.600',
						borderColor: 'primary.600',
					},
				},
				_focusVisible: {
					_web: {
						style: {
							outlineWidth: '0px',
						},
					},
				},
				_disabled: {
					bg: 'gray.300',
					_web: {
						cursor: 'not-allowed',
					},
					opacity: 0.4,
					_text: {
						opacity: 0.4,
						color: 'gray.800',
					},
				},
			},
		},
		Radio: {
			baseStyle: {
				_checked: {
					_icon: {
						color: 'singletons.white',
					},
					bg: 'primary.400',
					borderColor: 'primary.400',
					_hover: {
						bg: 'primary.600',
						_icon: {
							color: 'singletons.white',
						},
						borderColor: 'primary.600',
					},
				},
			},
		},
		Divider: {
			baseStyle: {
				bg: 'gray.100',
			},
		},
	},
}
