import axios from 'axios'
import { LocalStorage } from 'app/utils/storage'
import { Platform } from 'react-native'
import API_END_POINT from 'app/utils/apiEndPoint'
import { ignoreDuplicationRequest } from 'app/utils/axios/duplicate-request-blocker'
import { ClientError, INVALID_TOKEN } from '@bridge/core/util/error'

const REFRESH_TOKEN_URL = (API_END_POINT.HOST ?? '') + '/api/auth/refresh-token'

const serverAccess = axios.create({
	baseURL: (API_END_POINT.HOST ?? '') + '/api',
	headers: { 'Content-Type': 'application/json' },
	withCredentials: false,
})

const localServerMap = {
	auth: API_END_POINT.AUTH,
	diagnostic: API_END_POINT.DIAGNOSTIC,
	admin: API_END_POINT.ADMIN,
	notification: API_END_POINT.NOTIFICATION,
	payment: API_END_POINT.PAYMENT,
	consulting: API_END_POINT.CONSULTING,
	study: API_END_POINT.STUDY,
}

// const localServerMap = {
//   auth: 'https://stg.bridgeproject.co.kr',
//   diagnostic: 'https://stg.bridgeproject.co.kr',
//   admin: 'https://stg.bridgeproject.co.kr',
//   notification: 'https://stg.bridgeproject.co.kr',
//   payment: 'https://stg.bridgeproject.co.kr',
//   consulting: 'https://stg.bridgeproject.co.kr',
//   study: 'https://stg.bridgeproject.co.kr'
// }

serverAccess.interceptors.request.use(async (config) => {
	// ios 환경에서 next proxy 경유할 경우 요청헤더에서 Authorization 값이 사라지는 것 때문에 개발환경에서 앱인 경우에만 동작해야 함
	if (process.env.NODE_ENV === 'test') {
		config.baseURL = 'http://localhost:3000' + '/api'
	}

	if (process.env.NODE_ENV === 'development' && Platform.OS !== 'web') {
		const [target1 = '', target2 = ''] = config.url?.split('/') ?? []
		// @ts-ignore
		const targetBaseUrl = localServerMap?.[target1] || localServerMap?.[target2]
		config.baseURL = targetBaseUrl ?? '' + '/api'
	}

	if (!config.headers.Authorization) {
		const token = LocalStorage.getIdToken()

		if (token) {
			config.headers.Authorization = `Bearer ${token}`
		}
	}

	ignoreDuplicationRequest(config)

	return config
})

serverAccess.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config

		if (
			error.config?.url &&
			error.config.url !== '/auth/sign-in' &&
			error.response?.status === 401 &&
			!originalRequest._retry
		) {
			originalRequest._retry = true
			const newIdToken = await refreshIdToken()

			if (newIdToken) {
				originalRequest.headers.Authorization = `Bearer ${newIdToken}`

				return await axios(originalRequest)
			}
		}

		return await Promise.reject(error)
	},
)

export async function refreshIdToken (): Promise<string | undefined> {
	const refreshToken = LocalStorage.getRefreshToken()

	if (!refreshToken) {
		return undefined
	}

	const { data } = await axios
		.put(REFRESH_TOKEN_URL, { refreshToken })
		.catch(async (e) => {
			console.error('refresh token error', e)

			throw new ClientError({
				code: INVALID_TOKEN,
				message: '토큰 갱신에 실패했습니다',
			})
		})

	const { IdToken, AccessToken } = data.AuthenticationResult as { IdToken: string, AccessToken: string }

	LocalStorage.setIdToken(IdToken)
	LocalStorage.setAccessToken(AccessToken)

	return IdToken
}

export default serverAccess
