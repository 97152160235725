/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { type Theme } from '@mui/material/styles'
import { type SxProps, type SliderProps } from '@mui/material'
import { colors as themeColors } from '../colors'
import { TEXT } from '../legacy-native-base'

declare module '@mui/material/Slider' {
	interface SliderPropsSizeOverrides {
		large: true
	}
}

const MAX = 100
const MIN = 0
export const marksDefault = [
	{
		value: MIN,
		label: '0%',
	},
	{
		value: MAX,
		label: '100%',
	},
]

const sizeMap = {
	large: {
		w: 327,
		h: 10,
		thumb: 18,
		mark: {
			bar: { w: 1, h: 20 },
			dot: { w: 6, h: 6 },
		},
	},
	medium: {
		w: 327,
		h: 8,
		thumb: 16,
		mark: {
			bar: { w: 1, h: 18 },
			dot: { w: 4, h: 4 },
		},
	},
	small: {
		w: 327,
		h: 4,
		thumb: 12,
		mark: {
			bar: { w: 1, h: 12 },
			dot: { w: 2, h: 2 },
		},
	},
}

export const Slider = (theme: Theme) => {
	type StyleOption = {
		props: SliderProps
		style: SxProps<Theme>
	}

	const sizes: StyleOption[] = [
		{
			props: { size: 'large' },
			style: {
				width: sizeMap.large.w,
				height: sizeMap.large.h,

				'& .MuiSlider-thumb': {
					width: sizeMap.large.thumb,
					height: sizeMap.large.thumb,
				},
				'& .MuiSlider-track': {
					height: sizeMap.large.h,
				},
			},
		},
		{
			props: { size: 'medium' },
			style: {
				width: sizeMap.medium.w,
				height: sizeMap.medium.h,

				'& .MuiSlider-thumb': {
					width: sizeMap.medium.thumb,
					height: sizeMap.medium.thumb,
				},
				'& .MuiSlider-track': {
					height: sizeMap.medium.h,
				},
			},
		},
		{
			props: { size: 'small' },
			style: {
				width: sizeMap.small.w,
				height: sizeMap.small.h,

				'& .MuiSlider-thumb': {
					width: sizeMap.small.thumb,
					height: sizeMap.small.thumb,
				},
				'& .MuiSlider-track': {
					height: sizeMap.small.h,
				},
			},
		},
	]

	const addPercentSign = (label: string) => `${label}%`

	return {
		MuiSlider: {
			defaultProps: {
				size: 'large',
				min: MIN,
				max: MAX,
				marks: marksDefault, // true 일때 markLabel을 안 보여줌; false 일때 아무것도 안 보여줌
				step: 5,
				valueLabelFormat: addPercentSign,
				disableSwap: true,
			},
			variants: sizes,
			styleOverrides: {
				root: ({ ownerState }) => {
					const { size, disabled, marks, defaultValue, valueLabelDisplay } = ownerState
					const marksDisplay = marks === true || marks.length > 2
					const isMarkShapeDot = defaultValue && defaultValue.length > 1
					const markShape = isMarkShapeDot ? 'dot' : 'bar'
					const isValueLabelDisplayOff = valueLabelDisplay === 'off'

					return {
						margin: 10,
						...(!isValueLabelDisplayOff && {
							marginTop: 40,
							marginLeft: 30,
						}),

						'& .MuiSlider-thumb': {
							color: disabled ? themeColors.grey100 : themeColors.white,
							border: (disabled ? '0.75' : '1.13') + 'px solid',
							borderColor: themeColors.grey300,

							'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
								boxShadow: '0px 0px 0px 8px rgba(2, 32, 71, 0.05)',
							},
						},
						'& .MuiSlider-rail': {
							color: themeColors.grey100,
							opacity: 1,
						},
						'& .MuiSlider-track': {
							color: disabled ? themeColors.grey300 : themeColors.green400,
						},
						'& .MuiSlider-valueLabel': { // current value of the slider (말풍선)
							borderRadius: 8,
							top: -14,
							backgroundColor: disabled ? themeColors.grey400 : themeColors.grey800,
							...TEXT.sizes.p_s_700,
						},
						'& .MuiSlider-mark': { // grid
							height: sizeMap[size].mark[markShape].h,
							width: sizeMap[size].mark[markShape].w,
							borderRadius: isMarkShapeDot ? '50%' : 1,
							backgroundColor: (!isMarkShapeDot && marksDisplay) ? themeColors.grey200 : themeColors.transparent,
						},
						...(isMarkShapeDot && {
							'& .MuiSlider-mark[style*="left: 0%;"]': {
								backgroundColor: themeColors.grey200,
								transform: 'translate(50%, -50%)',
							},
							'& .MuiSlider-mark[style*="left: 100%;"]': {
								backgroundColor: themeColors.grey200,
								transform: 'translate(-150%, -50%)',
							},
						}),
						'& .MuiSlider-markLabel': { // grid label
							height: 18,
							width: 19,
							color: themeColors.grey500,
							...TEXT.sizes.p_xs_500,
						},
						'& .MuiSlider-markLabel[style*="left: 0%;"]': {
							transform: 'translateX(0)',
						},
						'& .MuiSlider-markLabel[style*="left: 100%;"]': {
							transform: 'translateX(-150%)',
						},
					}
				},
			},
		},
	}
}
