/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { type SxProps, type Theme } from '@mui/material/styles'
import { type TypographyProps } from '@mui/material'
import { TEXT } from '../legacy-native-base'
import { colors as themeColors } from '../colors'

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		h1_900: true
		h1_800: true

		h2_900_main: true
		h2_900: true
		h2_800_main: true
		h2_800: true
		h2_400: true

		h3_900_main: true
		h3_900: true
		h3_800: true
		h3_700: true

		h4_800: true
		h4_700_main: true
		h4_700: true
		h4_600: true
		h4_500_main: true
		h4_500: true

		h5_900: true
		h5_800: true
		h5_700: true
		h5_600: true
		h5_500_main: true
		h5_500: true

		h6_800_main: true
		h6_800: true
		h6_700: true
		h6_600: true
		h6_500: true
		h6_400: true

		h7_800: true
		h7_700: true
		h7_600: true
		h7_500_main: true
		h7_500: true

		p_m_800: true
		p_m_700: true
		p_m_600: true
		p_m_500: true
		p_m_400: true

		p_s_800: true
		p_s_700: true
		p_s_600: true
		p_s_500: true
		p_s_400: true

		p_xs_800: true
		p_xs_700: true
		p_xs_600: true
		p_xs_500: true

		label_bold_700: true

		libre: true
		libre_base: true

		text: true
	}
}

export const Typography = (theme: Theme) => {
	type StyleOption = {
		props: TypographyProps
		style: SxProps<Theme>
	}

	const headingVariants: StyleOption[] = [
		{
			props: { variant: 'h1_900' },
			style: {
				...TEXT.sizes.h1_900,
			},
		},
		{
			props: { variant: 'h1_800' },
			style: {
				...TEXT.sizes.h1_800,
			},
		},
		{
			props: { variant: 'h2_900_main' },
			style: {
				...TEXT.sizes.h2_900_main,
			},
		},
		{
			props: { variant: 'h2_900' },
			style: {
				...TEXT.sizes.h2_900,
			},
		},
		{
			props: { variant: 'h2_800_main' },
			style: {
				...TEXT.sizes.h2_800_main,
			},
		},
		{
			props: { variant: 'h2_800' },
			style: {
				...TEXT.sizes.h2_800,
			},
		},
		{
			props: { variant: 'h2_400' }, // nowhere used
			style: {
				...TEXT.sizes.h2_400,
			},
		},
		{
			props: { variant: 'h3_900_main' },
			style: {
				...TEXT.sizes.h3_900_main,
			},
		},
		{
			props: { variant: 'h3_900' },
			style: {
				...TEXT.sizes.h3_900,
			},
		},
		{
			props: { variant: 'h3_800' },
			style: {
				...TEXT.sizes.h3_800,
			},
		},
		{
			props: { variant: 'h3_700' }, // only used in HTML tags
			style: {
				...TEXT.sizes.h3_700,
			},
		},
		{
			props: { variant: 'h4_800' },
			style: {
				...TEXT.sizes.h4_800,
			},
		},
		{
			props: { variant: 'h4_700_main' },
			style: {
				...TEXT.sizes.h4_700_main,
			},
		},
		{
			props: { variant: 'h4_700' },
			style: {
				...TEXT.sizes.h4_700,
			},
		},
		{
			props: { variant: 'h4_600' },
			style: {
				...TEXT.sizes.h4_600,
			},
		},
		{
			props: { variant: 'h4_500_main' },
			style: {
				...TEXT.sizes.h4_500_main,
			},
		},
		{
			props: { variant: 'h4_500' },
			style: {
				...TEXT.sizes.h4_500,
			},
		},
		{
			props: { variant: 'h5_900' }, // nowhere used
			style: {
				...TEXT.sizes.h5_900,
			},
		},
		{
			props: { variant: 'h5_800' },
			style: {
				...TEXT.sizes.h5_800,
			},
		},
		{
			props: { variant: 'h5_700' },
			style: {
				...TEXT.sizes.h5_700,
			},
		},
		{
			props: { variant: 'h5_600' },
			style: {
				...TEXT.sizes.h5_600,
			},
		},
		{
			props: { variant: 'h5_500_main' },
			style: {
				...TEXT.sizes.h5_500_main,
			},
		},
		{
			props: { variant: 'h5_500' },
			style: {
				...TEXT.sizes.h5_500,
			},
		},
		{
			props: { variant: 'h6_800_main' },
			style: {
				...TEXT.sizes.h6_800_main,
			},
		},
		{
			props: { variant: 'h6_800' },
			style: {
				...TEXT.sizes.h6_800,
			},
		},
		{
			props: { variant: 'h6_700' },
			style: {
				...TEXT.sizes.h6_700,
			},
		},
		{
			props: { variant: 'h6_600' },
			style: {
				...TEXT.sizes.h6_600,
			},
		},
		{
			props: { variant: 'h6_500' },
			style: {
				...TEXT.sizes.h6_500,
			},
		},
		{
			props: { variant: 'h6_400' }, // nowhere used
			style: {
				...TEXT.sizes.h6_400,
			},
		},
		{
			props: { variant: 'h7_800' },
			style: {
				...TEXT.sizes.h7_800,
			},
		},
		{
			props: { variant: 'h7_700' },
			style: {
				...TEXT.sizes.h7_700,
			},
		},
		{
			props: { variant: 'h7_600' },
			style: {
				...TEXT.sizes.h7_600,
			},
		},
		{
			props: { variant: 'h7_500_main' }, // nowhere used
			style: {
				...TEXT.sizes.h7_500_main,
			},
		},
		{
			props: { variant: 'h7_500' },
			style: {
				...TEXT.sizes.h7_500,
			},
		},
	]

	const paragraphVariants: StyleOption[] = [
		{
			props: { variant: 'p_m_800' },
			style: {
				...TEXT.sizes.p_m_800,
			},
		},
		{
			props: { variant: 'p_m_700' },
			style: {
				...TEXT.sizes.p_m_700,
			},
		},
		{
			props: { variant: 'p_m_600' },
			style: {
				...TEXT.sizes.p_m_600,
			},
		},
		{
			props: { variant: 'p_m_500' },
			style: {
				...TEXT.sizes.p_m_500,
			},
		},
		{
			props: { variant: 'p_m_400' },
			style: {
				...TEXT.sizes.p_m_400,
			},
		},
		{
			props: { variant: 'p_s_800' },
			style: {
				...TEXT.sizes.p_s_800,
			},
		},
		{
			props: { variant: 'p_s_700' },
			style: {
				...TEXT.sizes.p_s_700,
			},
		},
		{
			props: { variant: 'p_s_600' },
			style: {
				...TEXT.sizes.p_s_600,
			},
		},
		{
			props: { variant: 'p_s_500' },
			style: {
				...TEXT.sizes.p_s_500,
			},
		},
		{
			props: { variant: 'p_s_400' },
			style: {
				fontSize: '13px',
				lineHeight: '21px',
				fontWeight: 400,
			},
		},
		{
			props: { variant: 'p_xs_800' },
			style: {
				...TEXT.sizes.p_xs_800,
			},
		},
		{
			props: { variant: 'p_xs_700' },
			style: {
				...TEXT.sizes.p_xs_700,
			},
		},
		{
			props: { variant: 'p_xs_600' },
			style: {
				...TEXT.sizes.p_xs_600,
			},
		},
		{
			props: { variant: 'p_xs_500' },
			style: {
				...TEXT.sizes.p_xs_500,
			},
		},
	]

	const labelVariants: StyleOption[] = [
		{
			props: { variant: 'libre' },
			style: {
				...TEXT.sizes.libre,
			},
		},
		{
			props: { variant: 'libre_base' },
			style: {
				...TEXT.sizes.libre_base,
			},
		},
	]

	const libreVariants: StyleOption[] = [
		{
			props: { variant: 'label_bold_700' },
			style: {
				...TEXT.sizes.Label_BOLD_700,
			},
		},
	]

	const colors: StyleOption[] = [
		{
			props: { color: 'darkMode' },
			style: {
				color: theme.palette.common.white,
				backgroundColor: theme.palette.common.black,
			},
		},
		{
			props: { color: 'lightMode' },
			style: {
				color: themeColors.grey900,
			},
		},
		{
			props: { color: 'black' },
			style: {
				color: theme.palette.common.black,
			},
		},
		{
			props: { color: 'greyScale' },
			style: {
				color: theme.palette.greyScale.main,
			},
		},
		{
			props: { color: 'disabled' },
			style: {
				color: themeColors.grey400,
			},
		},
		{
			props: { color: 'error' },
			style: {
				color: themeColors.red500,
			},
		},
	]

	return {
		MuiTypography: {
			defaultProps: {
				variant: 'text',
				color: 'black',
			},
			variants: [
				...headingVariants,
				...paragraphVariants,
				...labelVariants,
				...libreVariants,
				...colors,
			],
		},
	}
}
