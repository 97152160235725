import { TAB_BAR } from './path'

export * as PATH from './path'

export const TAB_BAR_PATH = {
	HOME: 'home',
	MY_PAGE: 'my-page',
}

export const PATH_OBJ = {
	TAB_BAR_HOME: {
		pathname: TAB_BAR,
		query: {
			path: TAB_BAR_PATH.HOME,
		},
	},
}
