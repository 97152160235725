/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { type Theme } from '@mui/material/styles'
import { type SxProps, type TextFieldProps } from '@mui/material'
import { colors as themeColors } from '../colors'
import { TEXT } from '../legacy-native-base'

declare module '@mui/material/TextField' {
	interface TextFieldPropsSizeOverrides {
		large: true
	}
	interface TextFieldPropsColorOverrides {
		greyScale: true
	}
}

export const TextField = (theme: Theme) => {
	type StyleOption = {
		props: TextFieldProps
		style: SxProps<Theme>
	}

	const sizes: StyleOption[] = [
		{
			props: { size: 'large' },
			style: {
				'& .MuiInputBase-input': {
					height: 56,
				},
			},
		},
		{
			props: { size: 'medium' },
			style: {
				'& .MuiInputBase-input': {
					height: 48,
				},
			},
		},
	]

	// outlined만 override
	return {
		MuiTextField: {
			defaultProps: {
				size: 'medium',
				color: 'greyScale',
				variant: 'outlined',
				InputLabelProps: { shrink: true }, // label은 항상 상단에
			},
			variants: sizes,
			styleOverrides: {
				root: ({ ownerState, theme }) => {
					const { color, label } = ownerState
					const helperTextColorMap = {
						greyScale: themeColors.grey500,
						error: theme.palette.error.main,
						primary: theme.palette.primary.main,
					}

					return {
						...(label && {
							paddingTop: 29,
						}),

						// label
						'& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-focused, & .MuiFormLabel-root.Mui-disabled': {
							...TEXT.sizes.p_s_600,
							color: themeColors.grey700,
							transform: 'translate(0, 0)',
						},
						// label 감싸는 부분 지워줌
						'& .MuiOutlinedInput-notchedOutline': {
							display: 'none',
						},
						// input 감싸는 div
						'& .MuiInputBase-root': {
							border: 0,
							padding: 0,
						},
						'& .MuiInputBase-input': {
							...TEXT.sizes.h7_500,
							margin: 0,
							borderRadius: 12,
							boxSizing: 'border-box',
							color: themeColors.grey900,
							border: '1px solid',
							borderColor: color === 'error' ? theme.palette.error.main : themeColors.grey200,

							'&:hover, &:focus': {
								color: themeColors.grey900,
								border: '1px solid',
								borderColor: color === 'error' ? theme.palette.error.main : themeColors.grey900,
							},
						},
						'& .MuiInputBase-input.Mui-disabled': {
							color: themeColors.grey500,
							backgroundColor: themeColors.grey50,
							border: '1px solid',
							borderColor: themeColors.grey300,

							'&:hover': {
								border: '1px solid',
								borderColor: themeColors.grey300,
							},
						},
						// helper text
						'& .MuiFormHelperText-root': {
							...TEXT.sizes.p_s_600,
							color: helperTextColorMap[color],
						},
						'& .MuiFormHelperText-root.Mui-disabled': {
							color: themeColors.grey500,
						},
						// icon
						'& .MuiInputAdornment-positionStart': {
							position: 'absolute',
							left: 10,
						},
						'& .MuiInputAdornment-positionEnd': {
							position: 'absolute',
							right: 10,
						},
					}
				},
			},
		},
	}
}
