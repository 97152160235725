type KnowreErrorResponse = {
	error: {
		code: string
		message: string
		svMessage: string
		status: number
	}
}

export type KnowreError = {
	response: {
		status: number
		data: KnowreErrorResponse
	}
}

export const isKnowreError = (error: any): error is KnowreError => {
	if (error.response?.data?.error) {
		return true
	}

	return false
}
