import { type Theme } from '@mui/material/styles'

export const Stack = (theme: Theme) => {
	return {
		MuiStack: {
			defaultProps: {
				useFlexGap: true, // false 일때는 flex item에 margin을 넣어서 gap을 조절함.
			},
		},
	}
}
