/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { type ToggleButtonGroupProps } from '@mui/material'
import { type Theme, type SxProps } from '@mui/material/styles'
import { TEXT } from '../legacy-native-base'
import { colors as themeColors } from '../colors'

declare module '@mui/material/ButtonGroup' {
	interface ToggleButtonGroupPropsColorOverrides {
		greyScale: true
	}
}

const iconSizeMap = {
	large: 24,
	medium: 20,
	small: 20,
}
const buttonPaddingMap = {
	large: 16,
	medium: 12,
	small: 8,
}
const fontSizeMap = {
	large: {
		...TEXT.sizes.btn_l_700,
	},
	medium: {
		...TEXT.sizes.btn_m_700,
	},
	small: {
		...TEXT.sizes.btn_s_700,
	},
}

export const ToggleButtonGroup = (theme: Theme) => {
	type StyleOption = {
		props: ToggleButtonGroupProps
		style: SxProps<Theme>
	}

	const colors: StyleOption[] = [
		{
			props: { color: 'primary' },
			style: {
				'& .MuiToggleButtonGroup-grouped': {
					backgroundColor: themeColors.white,
					'&:hover': {
						backgroundColor: 'rgba(57, 213, 121, 0.08)',
					},
				},
			},
		},
		{
			props: { color: 'secondary' },
			style: {
				'& .MuiToggleButtonGroup-grouped': {
					backgroundColor: themeColors.white,
					'&:hover': {
						backgroundColor: 'rgba(142, 51, 255, 0.08)',
					},
				},
			},
		},
	]

	return {
		MuiToggleButtonGroup: {
			defaultProps: {
				color: 'primary',
				size: 'medium',
				style: {
					color: themeColors.white,
					borderRadius: 8,
					border: '1px solid rgba(145, 158, 171, 0.08)',
					gap: 4,
					padding: 4,
				},
			},
			variants: colors,
			styleOverrides: {
				root: ({ ownerState }) => {
					const { size, style: { borderRadius } } = ownerState

					return {
						'& .MuiToggleButtonGroup-grouped': {
							...fontSizeMap[size],
							padding: buttonPaddingMap[size],
							borderRadius,
							border: 0,
							gap: 8,

							'&:hover': {
								border: 0,
							},
						},
						'& .MuiSvgIcon-root': {
							height: iconSizeMap[size],
							width: iconSizeMap[size],
						},
					}
				},
			},
		},
	}
}
