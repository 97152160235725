import { Platform } from 'react-native'
import { Image } from 'native-base'
// @ts-ignore
import { WithLocalSvg } from 'react-native-svg'

// @ts-ignore
const Svg = ({ src, asset, alt = '', ...props }) => {
	if (Platform.OS === 'web') {
		return <Image src={src} {...props} alt={alt ?? ''} />
	}
	return <WithLocalSvg asset={asset} {...props} />
}

export default Svg
