/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { alpha, type PaletteOptions } from '@mui/material/styles'
import { colors, ERROR, GREEN, GREY, GREY_SCALE } from './colors'

declare module '@mui/material/styles' {
	interface Palette {
		greyScale: Palette['primary']
	}

	interface PaletteOptions {
		greyScale?: PaletteOptions['primary']
	}
}

export const palette: PaletteOptions = {
	common: { black: '#000000', white: '#FFFFFF' },
	primary: GREEN,
	greyScale: GREY_SCALE,
	error: ERROR,
	// 하위 스타일은 admin-client의 minimal ui setting에서 가져옴. 필요 여부 판단 필요.
	divider: alpha(colors.grey500, 0.24),
	grey: GREY,
	text: {
		primary: GREY[900],
		secondary: GREY[600],
		disabled: GREY[500],
	},
	background: { paper: colors.white, default: colors.white },
	action: {
		hover: alpha(colors.grey500, 0.08),
		selected: alpha(colors.grey500, 0.16),
		disabled: alpha(colors.grey500, 0.8),
		disabledBackground: alpha(colors.grey500, 0.24),
		focus: alpha(colors.grey500, 0.24),
		hoverOpacity: 0.08,
		disabledOpacity: 0.48,
	},
}
