const buildComputerIP = 'localhost'

type HostURLType = {
	[index: string]: string
	local: string
	dev: string
	stg: string
	prd: string
}

const HOST_URL: HostURLType = {
	local: `http://${buildComputerIP}:${process.env.PORT ?? '3000'}`,
	// TODO: 웹 쪽 CORS 이슈로 인해 상대경로로 변경했습니다.
	// 추후 네이티브쪽을 고려해서 웹만 상대경로로 설정되도록 분기해야됨
	dev: '',
	stg: '',
	prd: '',
}

const API_END_POINT = {
	HOST: HOST_URL[`${process.env.NEXT_PUBLIC_STAGE ?? ''}`],
	AUTH: 'http://localhost:8901',
	DIAGNOSTIC: 'http://localhost:8902',
	ADMIN: 'http://localhost:8903',
	NOTIFICATION: 'http://localhost:8904',
	PAYMENT: 'http://localhost:8905',
	CONSULTING: 'http://localhost:8906',
	STUDY: 'http://localhost:8907',
}

export default API_END_POINT
