export const MAIN = '/'
export const TAB_BAR = '/tab-bar'
export const LOGIN = '/log-in'
export const LOGIN_FORGOT_PASSWORD = '/log-in/forget-password'
export const SIGN_UP = '/sign-up'
export const SIGN_UP_COMPLETE_EMAIL = '/sign-up/complete/email'

export const PARENT = '/parent'
export const PARENT_CHILDREN_CONNECTION = '/parent/children-connection'

export const CONSULTING_HISTORY = '/consulting/reservations'
export const CONSULTING_BOOK = '/consulting/reservation/book'

export const MY_PAGE_PARENT_CONNECT = '/my-page/parent-connection'
export const MY_PAGE_STUDY_PROFILE = '/my-page/study-profile'
export const MY_PAGE_SETTING = '/my-page/setting'
export const MY_PAGE_REGISTER_COUPON = '/my-page/register-coupon'
export const MY_PAGE_INQUIRY = '/my-page/inquiry'
export const MY_PAGE_UNSUBSCRIBE = '/my-page/unsubscribe'
export const MY_PAGE_PRIVACY = '/my-page/privacy'

export const SPECIAL_LECTURE = '/special-lecture'
export const SPECIAL_LECTURE_PLAYER = '/special-lecture/special-lecture-player'

export const DIAGNOSTIC_LIST = '/diagnostic/diagnostic-list'

export const BILLING_PAYMENT_HISTORY = '/billing/payment-history'
export const BILLING_MANAGE_PAYMENT = '/billing/manage-payment'
export const BILLING_REGISTER_CARD = '/billing/register-card'

export const PRICING = '/pricing'

export const AUTH_AGREEMENT = '/auth/agreement'
