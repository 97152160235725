/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { type Theme } from '@mui/material/styles'
import { type SvgIconProps } from '@mui/material'

declare module '@mui/material/Checkbox' {
	interface CheckboxProps {
		disableHover?: boolean
	}
}

const sizeMap = {
	small: 16,
	medium: 20,
	large: 24,
	inherit: 'inherit',
}

const CheckboxCheckedIcon = ({ fontSize }: SvgIconProps) => {
	const size = sizeMap[fontSize ?? 'medium']

	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<rect width="20" height="20" rx="4" fill="currentColor"/>
			<path d="M7.91869 12.7114L5.17719 10.0464L4 11.1907L7.91869 15L16 7.14432L14.8228 6L7.91869 12.7114Z" fill="#ffffff"/>
		</svg>
	)
}

const CheckboxIndeterminateIcon = ({ fontSize }: SvgIconProps) => {
	const size = sizeMap[fontSize ?? 'medium']

	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<rect width="20" height="20" rx="4" fill="currentColor"/>
			<path d="M16 9.5H4V11.375H16V9.5Z" fill="white"/>
		</svg>
	)
}

export const Checkbox = (theme: Theme) => {
	return {
		MuiCheckbox: {
			defaultProps: {
				icon: <CheckboxCheckedIcon />,
				checkedIcon: <CheckboxCheckedIcon />,
				indeterminateIcon: <CheckboxIndeterminateIcon />,
				size: 'medium',
			},
			variants: [
				{
					props: { disableHover: true },
					style: {
						color: theme.palette.grey[300],
						'&:hover': {
							color: `${theme.palette.grey[300]} !important`,
						},
						'.Mui-checked&:hover': {
							color: `${theme.palette.primary.main} !important`,
						},
					},
				},
			],
			styleOverrides: {
				root: {
					color: theme.palette.grey[300],
					'&:hover': {
						color: theme.palette.grey[400],
					},

					'&.Mui-disabled': {
						color: theme.palette.grey[300],
						opacity: 0.4,
					},

					'.Mui-checked': {
						color: theme.palette.primary.main,
					},

					'.Mui-checked&:hover': {
						color: theme.palette.primary.dark,
					},

					'.Mui-checked&.Mui-disabled': {
						color: theme.palette.primary.main,
					},

					'.MuiCheckbox-indeterminate&:hover': {
						color: theme.palette.primary.dark,
					},
				},
			},
		},
	}
}
