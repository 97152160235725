import serverAccess from 'app/utils/axios'

export type SignInReq = {
	username: string
	password: string
}

export type HicampusSignInReq = {
	seongJangPanUserId: string
}

export type SignUpStudentReq = {
	email: string
	password: string
	name: string
	gender: string
	birthdate: string
	phone_number: string
	parentPhoneNumber?: string
	schoolId: string
	grade: string
	referralCode?: string
	marketingConsent: string
}

export type SignUpParentReq = {
	email: string
	password: string
	name: string
	phone_number: string
	referralCode?: string
	marketingConsent: string
}

export type SignUpHicampusReq = {
	token: string
}

export type SignUpRes = {
	status: number
	data: {
		username: string
		client: {
			endpoint: string
		}
	}
}

export type VerifyCodeReq = {
	username: string
	otp: string
}

export type AuthDefaultRes = {
	status: number
	data: any
}

export type ResendVerificationCodeReq = {
	username: string
}

export type VerifyPhoneReq = {
	impUid: string
}

export type VerifyPhoneRes = {
	status: number
	data: {
		isParent: boolean
		phone: string
	}
}

export type ValidReferralCodeRes = {
	status: number
	data: {
		isValidReferralCode: boolean
	}
}

export type ConfirmForgotPasswordReq = {
	email: string
	password: string
	confirmCode: string
}

export type CheckExistEmailRes = {
	status: number
	data: {
		isExist: boolean
		isValid: boolean
		isConfirmed: boolean
	}
}

type TrialToRegularReq = {
	email: string
	password: string
	gender: string
	name: string
	birthdate: string
	referralCode: string
	schoolId: string
	grade: string
	marketingConsent?: boolean
	phone_number?: string
	parentPhoneNumber?: string
}

type AuthRepository = {
	signIn: (param: SignInReq) => Promise<AuthDefaultRes>
	hicampusSignIn: (param: HicampusSignInReq) => Promise<AuthDefaultRes>
	signUpParent: (param: SignUpParentReq) => Promise<SignUpRes>
	signUpStudent: (param: SignUpStudentReq) => Promise<SignUpRes>
	signInHicampusWithToken: (param: SignUpHicampusReq) => Promise<unknown>
	verifyCode: (param: VerifyCodeReq) => Promise<AuthDefaultRes>
	resendCode: (param: ResendVerificationCodeReq) => Promise<AuthDefaultRes>
	verifyPhone: (param: VerifyPhoneReq) => Promise<VerifyPhoneRes>
	validReferralCode: (code: string) => Promise<ValidReferralCodeRes>
	forgotPassword: (email: string) => Promise<{ status: number }>
	confirmForgotPassword: (
		param: ConfirmForgotPasswordReq,
	) => Promise<{ status: number }>
	checkEmailVerified: (email: string) => Promise<AuthDefaultRes>
	resendVerificationEmail: (email: string) => Promise<{ status: number }>
	checkExistEmail: (email: string) => Promise<CheckExistEmailRes>
	trialToRegular: (param: TrialToRegularReq) => Promise<{ status: number }>
}

let repository: AuthRepository

function authRepository (): AuthRepository {
	if (repository) {
		return repository
	}

	repository = {
		signIn: async (param) => {
			return await serverAccess.put('/auth/sign-in', param)
		},
		hicampusSignIn: async (param) => {
			return await serverAccess.put('/auth/daekyo-hicampus/student/account/sign-in', param)
		},
		signUpParent: async (param) => {
			return await serverAccess.post('/auth/sign-up/parent', param)
		},
		signUpStudent: async (param) => {
			return await serverAccess.post('/auth/sign-up/student', param)
		},
		signInHicampusWithToken: async (param) => {
			return await serverAccess.put('/auth/daekyo-hicampus/student/sign-in', param)
		},
		verifyCode: async (param) => {
			return await serverAccess.put('/auth/verify-otp', param)
		},
		resendCode: async (param) => {
			return await serverAccess.put(
				'/auth/resend-verification-otp',
				param,
			)
		},
		verifyPhone: async (param) => {
			return await serverAccess.post('/auth/verification', param)
		},
		validReferralCode: async (code) => {
			return await serverAccess.get('/auth/valid-referral-code/' + code)
		},
		forgotPassword: async (email) => {
			return await serverAccess.get('/auth/forgot-password/' + email)
		},
		confirmForgotPassword: async (param) => {
			return await serverAccess.put(
				'/auth/confirm-forgot-password',
				param,
			)
		},
		checkEmailVerified: async (email) => {
			return await serverAccess.get('/auth/email-verified/' + email)
		},
		resendVerificationEmail: async (email) => {
			return await serverAccess.put(
				'/auth/resend-verification-email/' + email,
			)
		},
		checkExistEmail: async (email) => {
			return await serverAccess.get('/auth/check-exist-email/' + email)
		},
		trialToRegular: async (param) => {
			return await serverAccess.patch('/ug/auth/trial-to-regular', param)
		},
	}

	return repository
}

export default authRepository()
