import { type InternalAxiosRequestConfig } from 'axios'

const THROTTLE_TIME = 500
const mutateMethod = new Set(['post', 'put', 'patch', 'delete'])
const requestMap = new Map<string, number>()

const isInThrottleTime = (lastRequestTime: number) => {
	return lastRequestTime + THROTTLE_TIME > Date.now()
}

const removeExpiredRequestKeys = () => {
	if (requestMap.size === 0) {
		return
	}

	const toRemoveKeys: string[] = []

	for (const [key, lastRequestTime] of requestMap.entries()) {
		if (!isInThrottleTime(lastRequestTime)) {
			toRemoveKeys.push(key)
		}
	}

	toRemoveKeys.forEach((key) => requestMap.delete(key))
}

const abortRequest = (config: InternalAxiosRequestConfig) => {
	const controller = new AbortController()

	config.signal = controller.signal
	controller.abort()
}

export const ignoreDuplicationRequest = (
	config: InternalAxiosRequestConfig,
) => {
	const method = config.method as string
	const url = config.url as string

	if (mutateMethod.has(method)) {
		const key = `${url}_${JSON.stringify(config.data)}`

		const lastRequestTime = requestMap.get(key) ?? 0

		removeExpiredRequestKeys()

		if (isInThrottleTime(lastRequestTime)) {
			abortRequest(config)

			return
		}

		requestMap.set(key, Date.now())
	}
}
