export const paymentQueryKey = {
	paymentCard: '/paymentCard',
	userProductWithRefund: '/ug/payment/product-with-refund',
	activeOrderInfo: '/active-order-info',
}

export const consultingQueryKey = {
	selectedChild: '/ug/consulting/selected-child',
	myReservations: '/ug/consulting/reservations',
}

export const diagnosisQueryKey = {
	sheetList: '/diagnostic/user-diagnostic-list',
}

export const studyQueryKey = {
	homePage: '/ug/study/home-page',
}

export const API_USER = 'API_USER'
