const colors = {
	pink50: '#FEEBF2',
	pink100: '#FBC4D7',
	pink200: '#F89CBD',
	pink300: '#F575A3',
	pink400: '#F24D88',
	pink500: '#EF266E',
	pink600: '#D91059',
	pink700: '#B20D48',
	pink800: '#8A0A38',
	pink900: '#630728',

	yellow50: '#FFF9E7',
	yellow100: '#FFEFBF',
	yellow200: '#FFE69B',
	yellow300: '#FFDD78',
	yellow400: '#FFD158',
	yellow500: '#FFC342',
	yellow600: '#FFB331',
	yellow700: '#FAA131',
	yellow800: '#EE8F11',
	yellow900: '#DD7D02',

	orange50: '#FFF3E0',
	orange100: '#FFE0B2',
	orange200: '#FFCC80',
	orange300: '#FFBD51',
	orange400: '#FFA927',
	orange500: '#FE9800',
	orange600: '#FB8800',
	orange700: '#F57800',
	orange800: '#ED6700',
	orange900: '#E45600',

	red50: '#FFEEEE',
	red100: '#FFD4D6',
	red200: '#FEAFB4',
	red300: '#FB8890',
	red400: '#F66570',
	red500: '#F04452',
	red600: '#E42939',
	red700: '#D22030',
	red800: '#BC1B2A',
	red900: '#A51926',

	purple50: '#EFEAFF',
	purple100: '#D0BFFF',
	purple200: '#B195FF',
	purple300: '#926AFF',
	purple400: '#7340FF',
	purple500: '#5415FF',
	purple600: '#3F00EA',
	purple700: '#3400BF',
	purple800: '#280095',
	purple900: '#1D006A',

	blue50: '#EBF7FE',
	blue100: '#C2E7FC',
	blue200: '#9AD8FA',
	blue300: '#72C8F7',
	blue400: '#49B8F5',
	blue500: '#21A8F3',
	blue600: '#0C93DE',
	blue700: '#0A78B6',
	blue800: '#085D8D',
	blue900: '#054365',

	teal50: '#EDF8F8',
	teal100: '#BCE9E9',
	teal200: '#89D8D8',
	teal300: '#58C7C7',
	teal400: '#30B6B6',
	teal500: '#18A5A5',
	teal600: '#109595',
	teal700: '#0C8585',
	teal800: '#097575',
	teal900: '#076565',

	blueGreen50: '#F0FAF6',
	blueGreen100: '#AEEFD5',
	blueGreen200: '#76E4B8',
	blueGreen300: '#3FD599',
	blueGreen400: '#15C47E',
	blueGreen500: '#03B26C',
	blueGreen600: '#02A262',
	blueGreen700: '#029359',
	blueGreen800: '#028450',
	blueGreen900: '#027648',

	green50: '#EAFBF0',
	green100: '#C0F2D2',
	green200: '#96E9B3',
	green300: '#6CE095',
	green400: '#2CD167',
	green500: '#28BE5D',
	green600: '#28BE5D',
	green700: '#166934',
	green800: '#0D3F1F',
	green900: '#04150A',

	grey50: '#F9FAFB',
	grey100: '#F2F4F6',
	grey200: '#E5E8EB',
	grey300: '#D1D6DB',
	grey400: '#B0B8C1',
	grey500: '#8B95A1',
	grey600: '#6B7684',
	grey700: '#4E5968',
	grey800: '#333D4B',
	grey900: '#191F28',

	greyOpacity50: 'rgba(0, 23, 51, 0.02)',
	greyOpacity100: 'rgba(2, 32, 71, 0.05)',
	greyOpacity200: 'rgba(0, 27, 55, 0.1)',
	greyOpacity300: 'rgba(0, 29, 58, 0.18)',
	greyOpacity400: 'rgba(0, 29, 54, 0.31)',
	greyOpacity500: 'rgba(3, 24, 50, 0.46)',
	greyOpacity600: 'rgba(0, 19, 43, 0.58)',
	greyOpacity700: 'rgba(3, 18, 40, 0.7)',
	greyOpacity800: 'rgba(0, 12, 30, 0.8)',
	greyOpacity900: 'rgba(2, 9, 19, 0.91)',

	black: '#000000',

	white50: '#ffffff',
	white100: '#f2f4f6',
	white200: '#f2f4f6',
	white500: '#8b95a1',
	white600: '#ffffff',
	white700: '#ffffff',

	transparent: '#00ff0000',
}

export default colors
