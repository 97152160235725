import { min } from 'lodash-es'
import { useWindowDimensions } from 'react-native'

const useWebDimensions = () => {
	const { width, ...rest } = useWindowDimensions()

	return {
		width: min([width, 500]) ?? 500,
		...rest,
	}
}

export default useWebDimensions
