import useRoute from '../../main/hooks/useRoute'
import Image from 'next/image'
import { noop } from 'lodash-es'
import { isHicampus } from '@bridge/core/constants/is-hicampus'

type LogoType = 'white' | 'black' | 'primary' | 'hicampus'

type LogoButtonProps = {
	logoType?: LogoType
	beforeOnPress?: () => void
}

const imageSrcMap = {
	white: '/main/logo.svg',
	black: '/main/logo_black.svg',
	primary: '/main/logo_primary.svg',
}

const LogoButton = ({ logoType = 'white', beforeOnPress = noop }: LogoButtonProps) => {
	const { routeMain } = useRoute()

	function logoOnClick () {
		beforeOnPress()
		routeMain().catch(console.error)
	}

	if (isHicampus) {
		return (
			<Image
				src='/main/logo_hicampus.svg'
				alt='briiidge project'
				width={168}
				height={32}
				style={{ cursor: 'pointer', padding: '0 14px', width: 168, height: 32 }}
				onClick={logoOnClick}
			/>
		)
	}

	return (
		<Image
			src={imageSrcMap[logoType]}
			alt='briiidge project'
			width={100}
			height={16}
			style={{ cursor: 'pointer', padding: '0 14px', width: 100, height: 16 }}
			onClick={logoOnClick}
		/>
	)
}

export default LogoButton
