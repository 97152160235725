/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { type ToggleButtonProps } from '@mui/material'
import { type SxProps, type Theme } from '@mui/material/styles'
import { colors as themeColors } from '../colors'
import { TEXT } from '../legacy-native-base'

declare module '@mui/material/ToggleButton' {
	interface ToggleButtonPropsColorOverrides {
		greyScale: true
	}
}

export const ToggleButton = (theme: Theme) => {
	type StyleOption = {
		props: Omit<ToggleButtonProps, 'value'>
		style: SxProps<Theme>
	}

	const colorStyleMap = {
		greyScale: {
			color: themeColors.grey600,
			backgroundColor: themeColors.grey100,
			borderColor: themeColors.grey200,
		},
		primary: {
			color: themeColors.green500,
			backgroundColor: themeColors.green100,
			borderColor: themeColors.green200,
		},
	}
	const colors: StyleOption[] = [
		{
			props: { disabled: true },
			style: {
				color: themeColors.grey400 + '!important',
				backgroundColor: themeColors.grey300 + '!important',
				borderColor: themeColors.grey300,
			},
		},
		{
			props: { color: 'greyScale' },
			style: {
				color: themeColors.grey500,
				borderColor: themeColors.grey200,
				'&:hover': colorStyleMap.greyScale,
				'&.Mui-selected': colorStyleMap.greyScale,
			},
		},
		{
			props: { color: 'primary' },
			style: {
				color: themeColors.green400,
				backgroundColor: themeColors.green50,
				borderColor: themeColors.green100,
				'&:hover': colorStyleMap.primary,
				'&.Mui-selected': colorStyleMap.primary,
			},
		},
	]

	return {
		MuiToggleButton: {
			defaultProps: {
				size: 'large',
				color: 'greyScale',
			},
			variants: colors,
			styleOverrides: {
				root: ({ ownerState }) => {
					const label = ownerState.children[1]

					return {
						border: '1px solid',

						'.MuiSelected, &:hover': {
							border: '1.5px solid',
						},
						'& .MuiToggleButton-sizeLarge': {
							...(label
								? {
									...TEXT.sizes.h7_600,
									height: 60,
									width: 112,
								}
								: {
									height: 56,
									width: 56,
								}),
						},
						'& .MuiToggleButton-sizeMedium': {
							...(label
								? {
									...TEXT.sizes.h7_600,
									height: 48,
									width: 100,
								}
								: {
									height: 48,
									width: 48,
								}),
						},
						'& .MuiToggleButton-sizeSmall': {
							...(label
								? {
									fontSize: 15,
									lineHeight: 18,
									fontWeight: 600,
									height: 40,
									width: 79,
								}
								: {
									height: 36,
									width: 36,
								}),
						},
					}
				},
			},
		},
	}
}
