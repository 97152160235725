import { Center, Container, Spinner } from 'native-base'

export default function LoadingSpinner () {
	return (
		<Center height="100%" width="100%" position="absolute">
			<Container>
				<Spinner color="emerald.500" />
			</Container>
		</Center>
	)
}
